import type { FC } from "react";
import type { Theme } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { useTheme } from "@mui/material/styles";

const LogoRoot = experimentalStyled("img")(({ theme }) => ({}));

const Logo: FC<{
  sx?: SxProps<Theme>;
  name?: boolean;
  loading?: boolean;
  size?: number;
}> = (props) => {
  const theme = useTheme();

  function getSrc() {
    return props.name === true
      ? `/static/assets/ILLUMIX_1COLOR_LOGO_${theme.palette.mode === "light" ? "NEG" : "POS"
      }.png`
      : `/static/assets/ILLUMIX_GLYPH_LOGO.png`;
  }

  return (
    <LogoRoot
      height={props.size || 52}
      width={props.size || 52}
      sx={
        props.loading
          ? { ...props.sx, animation: "grow 3s linear infinite" }
          : props.sx
      }
      alt="Illumix Logo"
      src={getSrc()}
    ></LogoRoot>
  );
};

export default Logo;
