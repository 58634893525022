import { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import DotIcon from "../icons/Dot";

const Blinking: FC<{ text?: string }> = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: 0,
        my: 1,
        p: 0,
        display: "inline-flex",
        flexDirection: "row",
      }}
    >
      {props.text ? (
        props.text.split("").map((char, indx) => (
          <Box
            key={`${indx}_blinking_box`}
            sx={{
              m: 0,
              p: 0,
              color: theme.palette.text.primary,
              animation: "1s blink infinite",
              animationDelay: `${indx * 250}ms`,
            }}
          >
            {char}
          </Box>
        ))
      ) : (
        <Box>
          <DotIcon
            sx={{
              m: 0,
              p: 0,
              color: theme.palette.text.primary,
              animation: "1s blink infinite",
              animationDelay: `${0 * 250}ms`,
            }}
          />
          <DotIcon
            sx={{
              m: 0,
              p: 0,
              color: theme.palette.text.primary,
              animation: "1s blink infinite",
              animationDelay: `${1 * 250}ms`,
            }}
          />
          <DotIcon
            sx={{
              m: 0,
              p: 0,
              color: theme.palette.text.primary,
              animation: "1s blink infinite",
              animationDelay: `${2 * 250}ms`,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Blinking;
