function validateValues<
  T extends object & {
    [propertyName: string]: any
  }
>(obj: T): T {
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) throw Error(`CONFIG INVALID VALUE FOR KEY - ${key}`)
  })

  return obj
}

export const auth0Config = validateValues({
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  illumix_key: process.env.REACT_APP_AUTH0_CUSTOM_FIELD!
})

export const envConfig = validateValues({
  env: process.env.NODE_ENV!,
  backend_env: process.env.REACT_APP_BACKEND_ENV!,
});
