import { RouteObject } from 'react-router'
import AuthGuard from './ShareWithDash/components/AuthGuard'
import MainLayout from './components/MainLayout'
import Authorize from './pages/Authorize'
import HomePage from './pages/HomePage'
import HyperionIntegrationTestPage from './pages/HyperionIntegrationTestPage'
import Redirector from './ShareWithDash/components/Redirector'

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <Redirector></Redirector>
    )
  },
  {
    path: 'home',
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
  },
  {
    path: 'hyp-integration',
    element: (
      <AuthGuard>
        <MainLayout>
          <HyperionIntegrationTestPage></HyperionIntegrationTestPage>
        </MainLayout>
      </AuthGuard>
    )

  },
  {
    path: 'authorize',
    element: (
      <Authorize></Authorize>
    )
  }
]

export default routes
