import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Divider, Toolbar } from "@mui/material";

import Logo from "../ShareWithDash/components/Logo";
import LoginButton from "./LoginButton";
// import LoginButton from "./LoginButton";

const MainNavbar: FC<{
  onSidebarMobileOpen?: () => void;
}> = (props) => {
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to="/">
          <Logo name sx={{ width: "inherit", height: "20px" }} />
        </RouterLink>

        <Box sx={{ flexGrow: 1 }} />

        <LoginButton />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
