import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store/reducers/store'
import routes from './routes'
import { useRoutes } from 'react-router'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from './theme'
import { CssBaseline } from '@mui/material'

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};
export interface Settings {
  compact: boolean;
  direction: "ltr" | "rtl";
  responsiveFontSizes: boolean;
  roundedCorners: boolean;
  theme: string;
}
const settings: Settings = {
  compact: true,
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: false,
  theme: window.matchMedia("(prefers-color-scheme: dark)").matches
    ? THEMES.DARK
    : THEMES.LIGHT,
};

const App: React.FC = () => {
  const content = useRoutes(routes)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider
          theme={createTheme({
            direction: settings.direction,
            responsiveFontSizes: settings.responsiveFontSizes,
            mode: settings.theme,
          })}
        >
          <CssBaseline />
          {content}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
