import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react'
import AppCard from '../components/AppCard';
import AppCards from '../components/AppCard';

const HomePage: FC = () => {
  const auth = useAuth0();


  const appList = [
    {
      title: "Hyperion-Apollo Integration Test",
      description: "Tests the integration of Hyperion-Web, Hyperion-WebSDK, and Apollo backend.",
      navigateTo: "/hyp-integration"
    }
  ];

  return (
    <Box sx={{ p: 10, flexGrow: "1" }}>
      {auth.isAuthenticated ? (
        <Grid container spacing={3}>
          {appList.map((app, i) => {
            return (
              <AppCard key={i} title={app.title} description={app.description} navigateTo={app.navigateTo}></AppCard>
            )
          })}
        </Grid>
      ) : (
        <h1>Please login</h1>
      )
      }
    </Box >
  )
}

export default HomePage
