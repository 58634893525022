import { useCallback, useEffect, useState } from "react";
import type { FC } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../lib/localStorage";
import { auth0Config } from "../../config";

// import { useGetUserByIdQuery } from "../../store/services/users";
// import { useGetOrgByIdQuery } from "../../store/services/orgs";

import SplashScreen from "./SplashScreen";
import { ApiResponseError } from "../types/response";
import Logger from "../lib/logger";

// const OrgFetchWrapper: FC<{ orgId: string; navigate: NavigateFunction }> = (
//   props
// ) => {
//   const { navigate, orgId } = props;

//   const { data: org, error: orgErr } = useGetOrgByIdQuery({
//     id: orgId,
//     include: ["tier", "settings"],
//   });

//   useEffect(() => {
//     if (orgErr != null) {
//       const err = orgErr as ApiResponseError;
//       navigate(
//         `/${err.status && typeof err.status === "number" ? err.status : 500}`
//       );
//     }

//     if (org?.data != null) {
//       navigate(`/organizations/${org.data.id}`);
//     }
//   }, [navigate, orgErr, org]);

//   return <></>;
// };

// const UserFetchWrapper: FC<{ userId: string; navigate: NavigateFunction }> = (
//   props
// ) => {
//   const { navigate, userId } = props;

//   const { data: user, error: userErr } = useGetUserByIdQuery({
//     id: userId,
//     include: ["permissions"],
//   });

//   useEffect(() => {
//     if (userErr != null) {
//       const err = userErr as ApiResponseError;
//       navigate(
//         `/${err.status && typeof err.status === "number" ? err.status : 500}`
//       );
//     }
//   }, [navigate, userErr]);

//   return (
//     <>
//       {user?.data != null && (
//         <OrgFetchWrapper
//           key={`redirector_${Date.now()}`}
//           orgId={user.data.orgId}
//           navigate={props.navigate}
//         />
//       )}
//     </>
//   );
// };

const Redirector: FC = () => {
  const navigate = useNavigate();
  const auth = useAuth0();

  const [freshTokenSaved, setFreshTokenSaved] = useState(false);

  const setStorageToken = useCallback(async () => {
    await auth
      .getAccessTokenSilently()
      .then((token) => setLocalStorage({ key: "accessToken", value: token }));
  }, [auth]);

  const userAuthenticated = auth.isAuthenticated === true;
  const userExists = auth.user != null;
  const tokenInStorage = getLocalStorage({ key: "accessToken" }) != null;
  const authError = auth.error;

  useEffect(() => {
    if (userAuthenticated) {
      console.log("🚀 ~ file: Redirector.tsx:97 ~ useEffect ~ userAuthenticated:", userAuthenticated)
      setStorageToken();
    } else {
      console.log("🚀 ~ file: Redirector.tsx:97 ~ useEffect ~ userAuthenticated:", userAuthenticated)
    }
  }, [userAuthenticated, setStorageToken]);

  useEffect(() => {
    Logger.debug({
      prefix: "Redirector.useEffect.params",
      msg: {
        userAuthenticated: userAuthenticated,
        userExists: userExists,
        tokenInStorage: tokenInStorage,
        authError: authError,
      },
    });

    if (authError) {
      Logger.error({ prefix: "Auth0.error", msg: authError });
      navigate(`/home`);
    } else if (userExists && userAuthenticated) {
      setStorageToken().then(() => setFreshTokenSaved(true));
      navigate('/hyp-integration')
    } else if (!userAuthenticated) {
      removeLocalStorage({ key: "accessToken" });
      navigate(`/home`);
    }
  }, [
    userAuthenticated,
    userExists,
    authError,
    tokenInStorage,
    setStorageToken,
    navigate,
  ]);

  return (
    <>
      <SplashScreen />
      {/* {userExists &&
        (freshTokenSaved || (userAuthenticated && tokenInStorage)) && (
          <UserFetchWrapper
            userId={auth.user![auth0Config.illumix_key]}
            navigate={navigate}
          />
        )} */}
    </>
  );
};

export default Redirector;
