// TODO: Shared Components Library
import { FC, ReactNode, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthGuard: FC<{ children: ReactNode }> = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }

      navigate("/");
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially requested route.
    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
      navigate(requestedLocation);
    }
  }, [isAuthenticated, pathname, requestedLocation, navigate]);

  return <>{props.children}</>;
};

export default AuthGuard;
