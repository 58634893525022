import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";

const AppCard: FC<{ navigateTo: string, title: string, description: string }> = (props) => {
  const navigate = useNavigate();

  return (
    <Card raised={true} sx={{ backgroundColor: "#022" }}>
      <CardHeader title={props.title}></CardHeader>
      <CardContent>{props.description}</CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button color="secondary" onClick={() => navigate(props.navigateTo)} variant="outlined">Click here to open tool.</Button>
      </CardActions>
    </Card>
  );
}
export default AppCard;