export type StorageKey = "accessToken" | "settings" | "tour";

export const setLocalStorage = ({
  key,
  value,
}: {
  key: StorageKey;
  value: any;
}): void => {
  return window.localStorage.setItem(key, value);
};

export const removeLocalStorage = ({ key }: { key: StorageKey }): void => {
  return window.localStorage.removeItem(key);
};

export const getLocalStorage = ({ key }: { key: StorageKey }) => {
  return window.localStorage.getItem(key);
};
