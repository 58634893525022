import { Illumix, IllumixSDK, IllumixSDKConfig, initializeSDK } from '@illumix-inc/hyperion-websdk';
import { Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Paper, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react'
import EditSDKOptionsDialog from '../components/SDKOptionsEditModal';

// We make sure to use the actual emitted types for 
// each function
export type SDKRuntimeConfigurations = {
  initializeSDK: Parameters<Illumix["initializeSDK"]>;
  start: Parameters<IllumixSDK["start"]>;
  preload: Parameters<IllumixSDK["preload"]>;
}

const HyperionIntegrationTestPage: FC = () => {
  // TODO (https://illumix.atlassian.net/browse/WEB-333): For now we'll use default versions from the package (which would be "latest" from Production)
  // Later, we should reimplement the build selector tool from Dashboard.
  // const [usedHypVer, setUsedHypVer] = useState("latest");

  const [fullscreen, setFullscreen] = useState(true);
  const [isExperienceRunning, setIsExperienceRunning] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState<{ title: string, content: string } | null>(null);

  const [illumixSDK, setIllumixSDK] = useState<IllumixSDK | null>(null);
  const [initializeSDKConfig, setInitializeSDKConfig] = useState<Parameters<Illumix["initializeSDK"]> | null>(null);
  const [startSDKConfig, setStartSDKConfig] = useState<Parameters<IllumixSDK["start"]> | null>(null);
  const [preloadSDKConfig, setPreloadSDKConfig] = useState<Parameters<IllumixSDK["preload"]> | null>(null);

  const [errorText, setErrorText] = useState("");

  // Recache the init function when our config changes
  const initializeIllumixSDK = useCallback(
    async () => {
      if (!initializeSDKConfig) {
        return;
      }

      const config = initializeSDKConfig[0];
      config.on_experience_closed = () => {
        setIsExperienceRunning(false);
      }

      try {
        setIllumixSDK(
          await initializeSDK(config)
        );
      } catch (error) {
        console.log("🚀 ~ file: HyperionIntegrationTestPage.tsx:49 ~ error:", error)
        if (typeof error === 'string') {
          setErrorText(error);
        } else if (typeof error === 'object' && error && (error as any).message) {
          setErrorText((error as any).message);
        }
      }

    }, [initializeSDKConfig]);

  // Re-initialize the SDK when our cached init function updates
  // and call start if we have start parameters set.
  useEffect(() => {
    initializeIllumixSDK().then(() => {
    }).catch((error) => { setErrorText(error) })
  }, [initializeIllumixSDK]);

  useEffect(() => {
    if (!startSDKConfig || !illumixSDK) {
      setErrorText("Missing 'Start' SKU config. Please go to 'Configure' to set SDK configurations!");
    } else {
      setErrorText("Use the controls above to test an SDK method!");
      startSDK();
    }
  }, [illumixSDK, startSDKConfig])

  useEffect(() => {
    if (!isExperienceRunning) {
      setErrorText("Use the controls above to test an SDK method!");
    } else {
      setErrorText("");
    }
  }, [isExperienceRunning])

  const onSDKUpdate = (sdkConfigs: SDKRuntimeConfigurations) => {
    console.log("🚀 ~ file: HyperionIntegrationTestPage.tsx:22 ~ onSDKUpdate ~ sdkConfigs:", sdkConfigs);

    setInitializeSDKConfig(sdkConfigs.initializeSDK);
    setStartSDKConfig(sdkConfigs.start);
    setPreloadSDKConfig(sdkConfigs.preload);
  }

  const startSDK = () => {
    if (!illumixSDK || !startSDKConfig || !startSDKConfig[0].sku) {
      setErrorText("Please go to 'Configure' to set SDK configurations!");
    } else {
      console.log("🚀 Starting experience...");
      setErrorText("");
      try {
        illumixSDK.start(...startSDKConfig);
        setIsExperienceRunning(true);
      } catch (error) {
        console.log("🚀 ~ file: HyperionIntegrationTestPage.tsx:107 ~ startSDK ~ error:", error)

      }
    }
  }

  const preloadSDK = () => {
    if (!illumixSDK || !preloadSDKConfig) {
      setErrorText("Please go to 'Configure' to set SDK configurations!");
    } else {
      console.log("🚀 Preloading experience...");
      setErrorText("");
      illumixSDK.preload(...preloadSDKConfig);
      setIsExperienceRunning(true);
    }
  }

  const stopSDK = () => {
    illumixSDK?.stop();
  }

  const onPurchase = () => {
    // TODO (https://illumix.atlassian.net/browse/WEB-334): We probably shouldn't be calling this on Production, 
    // so let's implement this later and add checks to only call in non-prod envs
    // illumixSDK?.onPurchase(this should not happen);
    setDialogText({ title: "On Purchase", content: "Not available to test on Production environment." })
    setIsDialogOpen(true);
  }

  const getClientId = () => {
    const clientId = illumixSDK?.getClientId();
    setDialogText({ title: "Get Client Id", content: clientId ?? "No client id found." });
    setIsDialogOpen(true);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", flex: "1" }}>
      <Toolbar sx={{ minHeight: 64, justifyContent: "center" }}>
        <Box>
        </Box>
        <Typography variant="caption" sx={{ mr: 4 }}>Env: Production</Typography>
        <ButtonGroup>
          <Button onClick={startSDK}>Start</Button>
          <Button onClick={stopSDK}>Stop</Button>
          <Button onClick={preloadSDK}>Preload</Button>
          <Button onClick={onPurchase}>On Purchase</Button>
          <Button onClick={getClientId}>Get Client Id</Button>
          <Dialog open={isDialogOpen} onClose={() => { setIsDialogOpen(false) }}>
            <DialogTitle>{dialogText?.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {dialogText?.content ?? "There is nothing to see here..."}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </ButtonGroup>
        <EditSDKOptionsDialog onUpdate={onSDKUpdate} mountNode="iframe-target">Configure</EditSDKOptionsDialog>
      </Toolbar>
      <Divider />
      {errorText
        ? <Typography align="center">{errorText}</Typography>
        : <Box sx={{ flex: "1" }}>
          <Paper
            id="iframe-target"
            sx={{
              height: fullscreen ? "calc(100% - 274px)" : "100%",
              width: "100%",
              position: "absolute",
            }}
          ></Paper>
        </Box>

        // <Box sx={{ flex: "1", display: "flex", minHeight: "100%" }} id="iframe-target"></Box>
      }
    </Box>
  )
}

export default HyperionIntegrationTestPage;
