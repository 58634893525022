import React, { FC } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Link, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth0 } from '@auth0/auth0-react'

const LoginButton: FC = (props) => {
  const { isLoading, user, isAuthenticated, logout } = useAuth0()
  console.log('🚀 ~ file: LoginButton.tsx:9 ~ user:', user)
  const navigate = useNavigate()

  const handleLogout = (): void => {
    try {
      logout({ returnTo: window.location.origin })
    } catch (err) {
      console.error('🚀 ~ file: LoginButton.tsx:16 ~ handleLogout ~ err:', err)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {user != null ? (
        <LoadingButton loading={isLoading} onClick={handleLogout} variant="contained" color="primary" component="button" size="small">
          Logout
        </LoadingButton>
      ) : (
        <LoadingButton loading={isLoading} onClick={() => navigate('/authorize')} variant="contained" color="primary" component="button" size="small">
          Login
        </LoadingButton>
      )}
    </Box>
  )
}

export default LoginButton
