import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Logo from "./Logo";
import Blinking from "./Blinking";

const SlashScreen: FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      }}
    >
      <Logo size={75} />
      <Box>
        <Typography color={theme.palette.text.primary} variant="subtitle2">
          <Blinking />
        </Typography>
      </Box>
    </Box>
  );
};

export default SlashScreen;
